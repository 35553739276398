.r-comparatif,
.r-comparatif-mobile {
    display: none;
    font-family: $font-family-alt;
    margin: space(2) auto;
    max-width: 1100px;

    @include mdlg {
        display: block;
    }

    hr {
        margin-top: space();
    }

    h1 {
        font-size: font-size(base);
        margin: 0 0 space(.5);
        text-align: left;
    }

    h2 {
        background: color(primary);
        border-bottom: 5px solid color(gray, lighter);
        color: color(light);
        font-family: $font-family-title;
        font-size: font-size(h2);
        font-weight: 700;
        margin-bottom: 0;
        padding: space();
        position: relative;
        text-align: center;

        &::after {
            border-style: solid;
            border-width: space(.5) space(.75) 0 space(.75);
            border-color: color(primary) transparent transparent transparent;
            bottom: 0;
            content: '';
            display: block;
            height: 0;
            left: 50%;
            position: absolute;
            transform: translate(-50%, 100%);
            width: 0;
        }

        &.r--populaire {
            &::before {
                background: color(premium);
                color: color(primary);
                content: 'Le plus choisi';
                display: inline-block;
                font-family: $font-family-title;
                font-size: 2vw;
                font-weight: 700;
                left: 50%;
                padding: 0 space(.25);
                position: absolute;
                top: 0;
                transform: translate(-50%, -50%);
                width: auto;

                @include mdlg {
                    font-size: font-size(extrasmall);
                    line-height: 1.5;
                    margin: space(.25);
                    padding: 2px space(.25);
                    top: 8px;
                    width: auto;
                }
            }

            span {
                background: color(premium);

                display: inline-block;
                height: 30px;
                position: absolute;
                right: 20px;
                top: 0;
                width: 24px;

                &:after {
                    border-bottom: 10px solid color(primary);
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    //border-style: solid;
                    bottom: 0;
                    content: "";
                    height: 0;
                    left: 0;
                    position: absolute;
                    width: 0;
                }
            }

        }
    }

    // tr {
    //     box-shadow: 0 2px 3px color(gray, light);

    //     &:first-child {
    //         box-shadow: none;
    //     }
    // }

    // td {
    //     background: color(light);
    //     border-bottom: 1px solid color(gray, lighter);
    //     border-right: space(.5) solid color(gray, lighter);
    //     padding: space();
    //     text-align: center;
    //     width: 25%;

    //     &:first-child {
    //         text-align: left;
    //     }

    //     &:last-child {
    //         border-right: 0;
    //     }

    //     &.r--top {
    //         vertical-align: top;
    //     }

    //     &.r--texte {
    //         color: color(check);
    //     }

    //     &.r--check {
    //         color: color(check);
    //         font-size: 0;
    //         position: relative;

    //         &::before {
    //             background: color(light);
    //             content: $icon-check;
    //             font-family: main;
    //             font-size: font-size(small);
    //             left: 0;
    //             position: absolute;
    //             top: 50%;
    //             transform: translateY(-50%);
    //             width: 100%;
    //         }
    //     }

    //     &.r--cross {
    //         color: color(primary);
    //         font-size: 0;
    //         position: relative;

    //         &::before {
    //             background: color(light);
    //             content: $icon-cross;
    //             font-family: main;
    //             font-size: font-size(small);
    //             left: 0;
    //             position: absolute;
    //             top: 50%;
    //             transform: translateY(-50%);
    //             width: 100%;
    //         }
    //     }
    // }

    .r-btn {
        margin: space() auto;
    }

    .r--notif-info {
        color: color(secondary);
        float: right;
        margin-top: space(.25);
    }
}

.r-comparatif {
    .r--populaire {
        span {
            background: color(premium);
            display: inline-block;
            height: 30px;
            position: absolute;
            right: 20px;
            top: 0;
            width: 24px;

            &:after {
                border-bottom: 10px solid color(primary);
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                bottom: 0;
                content: "";
                height: 0;
                left: 0;
                position: absolute;
                width: 0;
            }
        }
    }

    .achat-24 & {
        // td {
        //     width: 20%;
        // }
    }

    // .achat-24 & {
    //     tr {
    //         display: flex;
    //     }
    //
    //     td {
    //         width: calc((100% - (#{$big-col} * 2)) / 3);
    //
    //         &:nth-child(3),
    //         &:nth-child(4) {
    //             width: $big-col;
    //         }
    //     }
    // }
}

.r-comparatif--surtitre {
    td {
        background: none;
        padding: 0 space();
        vertical-align: bottom;

        .achat-24 & {
            &:nth-child(3) {
                width: 40%;
            }
        }

        // .achat-24 & {
        //     &:nth-child(3) {
        //         width: calc((#{$big-col} * 2));
        //     }
        //
        //     &:nth-child(4) {
        //         width: calc((100% - (#{$big-col} * 2)) / 3);
        //     }
        // }
    }

    h3 {
        color: color(primary);
        font-size: font-size(base);
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;
        padding: 0 50px;
        position: relative;
        white-space: nowrap;

        .achat-24 & {
            padding: 0 space(2);
        }

        &::after,
        &::before {
            border-top: 1px solid color(primary);
            content: '';
            display: block;
            height: 25px;
            position: absolute;
            top: 50%;
            width: 25px;
        }

        &::before {
            border-left: 1px solid color(primary);
            left: 0;
        }

        &::after {
            border-right: 1px solid color(primary);
            right: 0;
        }
    }
}

.r-comparatif--titre {
    color: color(primary);

    // td {
    //     padding: 0;
    //     vertical-align: top;

    //     &:first-child {
    //         padding: space();
    //     }
    // }
}

hr {

    .r-comparatif-mobile &,
    .r-comparatif--group & {
        background: color(gray, lighter);
        border: none;
        height: 1px;
        margin: space() 0;
    }
}

.r--acces {

    .r-comparatif-mobile &,
    .r-comparatif--group & {
        color: lighten(color(gray, base), 10%);
        display: flex;
        justify-content: center;
        font-family: $font-family-title;
        font-weight: 700;
        padding: 0 space(.5);

        img {
            margin: 0 space(.25);
        }
    }
}

.r-comparatif-mobile {
    display: grid;
    grid-template-areas:
        ". . ."
        "liste liste liste"
        "btn btn btn"
        "last last last";
    grid-template-columns: minmax(0, 33.33%) minmax(0, 33.33%) minmax(0, 33.33%);
    margin: space() space(.5) space(2);

    @include mdlg {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: inherit;
        grid-gap: space(.5);
        justify-content: center;
        margin: space(2) auto;
        max-width: 1100px;
    }

    .r--offre {
        background: none;
        display: contents;
        margin: space(.5);

        @include mdlg {
            background-color: color(light);
            display: block;
            margin: 0;
            padding: 0 0 space();

            &:hover {
                background-color: color(light);
            }
        }


        h2 {
            align-items: center;
            display: flex;
            font-size: font-size(base);
            justify-content: center;
            line-height: 120%;
            margin: 0;
            min-height: 70px;

            @include mdlg {
                border-bottom: 5px solid color(gray, lighter);
                color: color(light);
                font-family: $font-family-title;
                font-size: font-size(h2);
                font-weight: 700;
                margin-bottom: 0;
                padding: space(1.25) space();
                position: relative;
                text-align: center;
            }

            span {
                //border-top: 1px dashed color(light);
                color: color(light);
                display: none;
                font-size: 2.5vw;
                padding-top: space(.25);

                @include mdlg {
                    display: block;
                }
            }
        }


        p {
            font-weight: 700;
            padding: space(.5) space() 0;
        }

        &:nth-child(4) {
            display: block;
            grid-area: last;
            margin: 0;
            width: 100%;

            r-box--header {
                box-shadow: none;
                margin-bottom: 0;
            }

            r-box--body,
            r-box--footer {
                grid-area: none;
                display: block;
            }

            r-box--body {
                @include smmdlg {
                    display: grid;
                    grid-template-areas: "prix list""btn list";
                    grid-template-columns: 25vw 75vw;
                    padding: space();
                }
            }

            ul {
                margin: 0 auto;

                @include smmdlg {
                    display: flex;
                    column-gap: space();
                    flex-wrap: wrap;
                    grid-area: list;
                    max-width: calc(100% - #{space(4)});
                    width: 75vw;
                }
            }

            li {
                @include smmdlg {
                    width: calc(50% - (#{space(2)}/2));
                }

                &:last-child {
                    margin: 0;
                }
            }

            r-box--footer {
                box-shadow: none;
                grid-area: btn;
                text-align: center;

                @include smmdlg {
                    padding: 0;
                }
            }

            span:empty {
                //display: none;
            }

            .r--prix {
                @include smmdlg {
                    grid-area: prix;
                }
            }
        }
    }

    r-box--header,
    r-box--body,
    r-box--footer {
        box-shadow: 0 2px 3px color(gray, light);

        @include mdlg {
            box-shadow: none;
        }
    }

    r-box--header {
        background: color(light);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: space(.5) space(.25);
        padding: 0;
        width: auto;

        @include mdlg {
            margin: 0;
        }

        .r-box--description {
            display: none;
            font-size: font-size(small);
            text-align: center;

            @include mdlg {
                display: block;
            }
        }

        a {
            display: none;

            @include mdlg {
                display: block;
            }
        }
    }

    r-box--body {
        background: color(light);
        display: none;
        flex: 1;
        grid-area: liste;
        margin: 0 space(.25);
        width: auto;

        @include mdlg {
            display: block;
            margin: 0;
        }

        // ul {
        //     list-style: none;
        //     margin: space(2) auto 0;
        //     max-width: 400px;
        //     padding: 0 space();
        //     // position: relative;
        //     text-align: left;
        // }

        // li {
        //     border-bottom: 1px solid color(gray, light);
        //     margin-bottom: space();
        //     padding-bottom: space(.5);
        //     padding-left: space(2);
        //     position: relative;
        // }
        .r-box--description {
            font-size: font-size(small);
            text-align: center;
            max-width: 100%;

            @include mdlg {
                display: none;
            }
        }

        .r--check {
            &::before {
                // background: color(light);
                color: color(check);
                content: $icon-check;
                font-family: main;
                font-size: font-size(extrasmall);
                left: 0;
                position: absolute;
                top: 4px;
                width: 100%;
            }
        }

        .r--cross {
            color: color(gray, light);

            &::before {
                // background: color(gray, light);
                content: $icon-cross;
                font-family: main;
                font-size: font-size(base);
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        .-has-tooltip {
            float: right;
        }
    }

    r-box--footer {
        background: color(light);
        box-shadow: none;
        display: none;
        grid-area: btn;
        justify-content: center;
        margin: auto space(.25);
        // max-width: 300px;
        width: auto;

        @include mdlg {
            display: none;
        }

        .r-btn {
            margin: 0 space() space(.5);
        }
    }

    .-is-open {
        r-box--header {
            // margin-bottom: 0;
            // padding-bottom: space(.5);
            position: relative;

            &::before {
                border-style: solid;
                border-color: color(light) transparent transparent;
                border-width: 12px 18px 0;
                bottom: 1px;
                content: '';
                display: block;
                height: 0;
                transform: translate(-50%, 100%);
                left: 50%;
                position: absolute;
                width: 0;
                z-index: 4;

                @include mdlg {
                    display: none;
                }
            }

            &::after {
                border-style: solid;
                border-color: color(gray, lighter) transparent transparent;
                border-width: 20px 30px 0;
                bottom: -4px;
                content: '';
                display: block;
                height: 0;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 100%);
                width: 0;
                z-index: 1;

                @include mdlg {
                    display: none;
                }
            }
        }

        .r--prix {

            &::after {
                border-style: solid;
                border-color: lighten(color(gray, light), 5%) transparent transparent;
                border-width: 12px 18px 0;
                bottom: -2px;
                content: '';
                display: block;
                height: 0;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 100%);
                width: 0;
                z-index: 3;

                @include mdlg {
                    display: none;
                }
            }
        }

        r-box--body {
            display: block;
        }

        r-box--footer {
            display: flex;
            flex-wrap: wrap;

            @include mdlg {
                display: none;
            }
        }
    }
}

.price,
.r--prix {
    color: color(primary);
    display: grid;
    grid-template-areas:
        "prefixe prefixe"
        "detail detail"
        "suffixe suffixe";
    //grid-template-columns: minmax(0, 40%) minmax(0, 60%);
    text-align: center;

    @include mdlg {
        display: block;
    }

    .cm-form-left & {
        display: none;
    }
}

.r-prix--detail {
    align-items: center;
    display: contents;
    font-family: $font-family-title;
    font-weight: 700;
    grid-area: detail;

    @include mdlg {
        display: flex;
        justify-content: center;
    }

    //margin-top: - space(.5);
}

.r-prix--euro {
    flex: 1;
    font-size: 10vw;
    text-align: right;
    line-height: 1;

    @include mdlg {
        font-size: 4rem;

    }
}

.r-prix--cent {
    flex: 1;
    font-size: 3.5vw;
    text-align: left;

    @include smmdlg {
        font-size: font-size(h4);
    }
}

.r-prix--prefixe {
    font-family: $font-family-title;
    font-size: 3vw;
    grid-area: prefixe;
    padding: space() space(.5) space(.5);
    text-align: center;

    @include mdlg {
        font-size: font-size(base);
        padding: space(.5) 3px space();
        position: relative;
        //top: -12px;
    }
}

.r-prix--suffixe {
    display: block;
    font-family: $font-family-title;
    font-size: 3vw;
    grid-area: suffixe;
    margin: space() auto;

    @include mdlg {
        font-size: font-size(base);
        margin: space(.5) auto;
    }
}

.r-prix--barre {
    display: inline-block;
    grid-area: barre;
    min-height: 32px;
    overflow: hidden;
    padding-top: space(.5);

    span {
        background: color(primary);
        border-radius: 50%;
        color: color(light);
        font-size: 4vw;
        padding: space(.5);
        position: relative;
        top: space(-.5);

        @include mdlg {
            font-size: font-size(base);
        }
    }
}

r-tooltip {
    border-radius: space(.25);
    font-family: $font-family-base;
    font-size: font-size(small);
    line-height: normal;
    min-width: 20em;
    text-align: left;
}

.r--nbr-compte {
    color: color(primary);
    font-family: $font-family-title;
    font-size: font-size(nav);
    font-weight: 600;

    r-icon {
        margin-right: space(1.5);
        position: relative;


        &:before {
            font-size: font-size(nav);
            //margin-bottom: - space(.5);
            top: - space();
            position: absolute;
            left: 0;
        }

        &:last-of-type {
            margin-right: space(1.75);
            /*! margin-right: 1rem; */
        }
    }
}

// .r-comparatif--group {
//     display: none;
//     font-family: font-family(base);
//     grid-template-columns: 1fr 1fr 1fr;
//     grid-gap: space(.5);
//     justify-content: center;
//     margin: space(2) auto;
//     max-width: 1100px;

//     @include mdlg {
//         display: grid;
//     }

//     >r-box {
//         background-color: color(light);
//         width: 100%;
//         padding-bottom: space(.5);
//     }

//     .r-comparatif {
//         background-color: color(light);
//     }

//     h1 {
//         font-size: font-size(base);
//         margin: 0 0 8px;
//         text-align: left;
//     }

//     h2 {
//         background: color(primary);
//         //border-bottom: 5px solid color(gray, lighter);
//         color: color(light);
//         font-family: $font-family-title;
//         font-size: 1.8rem;
//         font-weight: 700;
//         margin-bottom: 0;
//         padding: space();
//         position: relative;
//         text-align: center;

//         &::after {
//             border-style: solid;
//             border-width: 8px 12px 0;
//             border-color: color(primary) transparent transparent;
//             bottom: 1px;
//             content: '';
//             display: block;
//             height: 0;
//             left: 50%;
//             position: absolute;
//             transform: translate(-50%, 100%);
//             width: 0
//         }
//     }

//     p {
//         color: color(gray, darker);
//         font-family: $font-family-title;
//         font-size: font-size(small);
//         font-weight: 700;
//         padding: space() 0 0;
//     }

//     .box-swg {
//         padding: space();
//     }

//     .r--populaire {
//         &::before {
//             background: color(premium);
//             color: color(primary);
//             content: 'Le plus choisi';
//             display: inline-block;
//             font-family: $font-family-title;
//             font-size: font-size(extrasmall);
//             font-weight: 700;
//             left: 50%;
//             line-height: 1.5;
//             padding: 2px 5px;
//             position: absolute;
//             top: 0;
//             transform: translate(-50%, -50%);
//             z-index: 2;
//             @include mdlg {
//                 top: space(1.25);
//             }
//         }

//         h2 {

//             span {
//                 background: color(premium);
//                 display: inline-block;
//                 height: 30px;
//                 position: absolute;
//                 right: 20px;
//                 top: 0;
//                 width: 24px;

//                 &:after {
//                     border-bottom: 10px solid color(primary);
//                     border-left: 12px solid transparent;
//                     border-right: 12px solid transparent;
//                     bottom: 0;
//                     content: "";
//                     height: 0;
//                     left: 0;
//                     position: absolute;
//                     width: 0;
//                 }
//             }
//         }

//     }

//     .r-prix--prefixe {
//         color: color(primary);
//         text-align: center;
//         font-size: font-size(small);
//     }
// }