// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------

// Colors
// -----------------------------------------------------------------------------
$white: #ffffff;
$black: #000000;

$colors:                             (
    // Grayscale
    //
    gray:                            (
      lighter:                     #ededed,
      light:                       #d1d1d1,
      base:                        #707070,
      dark:                        #515151,
      darker:                      #212121,
    ),
    // Branding
    //
    primary:                         #FF0030,
    secondary:                       #0000FF,
    tertiary:                        #330365, //#fdc800
    menu:                            #0000FF,
    submenu:                         #0000FF,
    button:                          #FF0030,
    check:                           #31B908,
    premium:                         #FFCF00,
    google:                          #4689F2,
    tooltip:                         #0000FF,
    blue-gray:                       #B2C6E2,
    blue-light:                      #EFF6FE,

    // UI Colors
    //
    success:                         (
        base:                        #deefdb,
        dark:                        #8d9a8b,
        darker:                      #4b534a
    ), //#099

);

$yellow-degrade: linear-gradient(250deg, rgba(253,200,0,1) 36%, rgba(253,185,0,1) 100%);
// Font
// Font, line-height, and color for body text, headings, and more.
// -----------------------------------------------------------------------------

// Base font family
$font-family-base:                   helvetica, arial, sans-serif;
// Title font family
$font-family-title:                  roboto_condensed, arial, sans-serif;
// Alternative font family
$font-family-alt:                    roboto_condensed, sans-serif;
//Font family handwriting
$font-family-alt-cursive:             'shadows', cursive, sans-serif;
//Font family handwriting
$font-family-button:             'monument',  sans-serif;

$font-family-roboto:             'roboto', arial, sans-serif;

$font-family-source:             'SourceSans3',  sans-serif;

// Font size
$font-size:                          (
    base:                            1rem,
    h1:                              2.3rem,
    h2:                              1.8rem,
    h3:                              1.5rem,
    h4:                              1.3rem,
    h5:                              1.12rem,
    h6:                              1rem,
    small:                           .875rem,
    extrasmall:                      .6rem,
    alt:                             1rem,
    nav:                             1.2rem,
    button:                          1.12rem
);

// Line height
$line-height:                        (
    base:                            1.5,
    h1:                              1,
    h2:                              1.5,
    h3:                              1.3,
    h4:                              1.3,
    h5:                              1.3,
    h6:                              1.3,
    alt:                             1.5,
    small:                           1.5,
);

// Grids
// -----------------------------------------------------------------------------

$grid-gutter-width:                  space(.5);
$html-max-width:                     1000px;

// Components
// -----------------------------------------------------------------------------

// Buttons
$btn-border-radius:                  0;
$btn-padding:                        space(.5) space(2);
$btn-font-size:                      font-size(button);
$btn-font-family:                    $font-family-alt;
$btn-font-weight:                    font-weight(bold);

// Breadcrumbs
$breadcrumb-separator-icon:          '–';

// Forms
// Base
$forms-input-color:                  color(gray, darker);
$forms-input-border-radius:          0px;
$forms-input-focus-border-color:     color(secondary);
$forms-input-focus-bg-color:         color(gray, lighter);
$forms-input-placeholder-color:      color(gray);

$custom-input-border-color--checked: color(secondary);
$custom-input-color--checked:        color(secondary);
$custom-control-indicator-size:      1.25rem;
$custom-select-arrow-bg-color:       color(secondary);
$custom-select-indicator-icon:  url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEyOSAxMjkiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5Ij4NCiAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJtMTIxLjMsMzQuNmMtMS42LTEuNi00LjItMS42LTUuOCwwbC01MSw1MS4xLTUxLjEtNTEuMWMtMS42LTEuNi00LjItMS42LTUuOCwwLTEuNiwxLjYtMS42LDQuMiAwLDUuOGw1My45LDUzLjljMC44LDAuOCAxLjgsMS4yIDIuOSwxLjIgMSwwIDIuMS0wLjQgMi45LTEuMmw1My45LTUzLjljMS43LTEuNiAxLjctNC4yIDAuMS01Ljh6Ii8+DQo8L3N2Zz4NCg==');

$custom-control-indicator-radio-icon:
url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

$logo-height: 40px;
$header-height: $logo-height + space(3);
