.inscription_aux_newsletters {
    background: color(light);
    width: 100%;
    padding: space();

    @include smmdlg {
        padding-left: space(2);
        padding-right: space(2);
    }

    #wrapper {
        padding: 0;
    }

    h1 {
        margin-top: 0;
        font-family: $font-family-title;
        font-weight: 700;
        color: color(primary);
        border-bottom: 1px solid color(gray, light);
        text-align: center;
        padding-bottom: space();
        margin-bottom: 0;
        font-size: 24px;

        &:before {
            content: $icon-devices;
            display: inline-block;
            font-size: 16px;
            font-family: 'main';
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            text-transform: none;
            margin-right: space();

            @include smmdlg {
                font-size: 20px;
            }
        }

        @include smmdlg {
            font-size: 35px;
        }
    }

    .intros {
        display: none;
        color: color(gray, dark);
        font-family: $font-family-base;
        margin-bottom: space(.5);
        font-size: font-size(h4);
        margin-top: 0;
        max-width: 600px;
    }

    form {
        display: flex;
        flex-direction: column;

        ul {
            margin: space() 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-left: -1%;
            margin-right: -1%;

            &:before,
            &:after {
                display: none;
            }

            li {
                list-style: none;
                border: 1px solid color(gray, light);
                box-shadow: 0 0 0 0 transparent;
                transition: all linear .2s;
                margin-left: 1%;
                margin-right: 1%;
                padding: 20px;
                font-size: 12px;
                width: 100%;
                margin-bottom: space(3);
                display: block;

                // &.active,
                // &.selected {
                //     box-shadow: 0 0 0 5px lighten($brand-primary, 15%);
                // }

                @include smmdlg {
                    width: 48%;
                    margin-bottom: 3%;
                    display: flex;
                    align-items: flex-start;
                }

                label {
                    color: color(primary);
                    font-family: $font-family-alt;
                    font-size: 24px;
                    margin: 0;
                    text-align: left;
                    display: block;

                    a {
                        color: color(gray, dark);
                        font-size: 0;

                        &:before {
                            font-family: main;
                            content:$icon-payment;
                            display: inline-block;
                            font-size:font-size(h3);
                            vertical-align: middle;
                        }
                    }
                }

                .image {
                    flex: 0 0 38%;
                    margin-right: 0;
                    margin-bottom: 20px;

                    img {
                        max-width: 100%;
                    }

                    @include smmdlg {
                        margin-right: 15px;
                        margin-bottom: 0;
                    }
                }

                .text {
                    flex: 1 1 auto;
                    display: block;
                    text-align: right;
                    cursor: pointer;

                    input {
                        display: none;
                    }

                    p {
                        text-align: left;
                    }

                    .boutAjout {
                        background: color(primary);
                        display: inline-block;
                        padding: 5px 0;
                        margin: space() auto;
                        text-align: center;
                        color: color(light);
                        position: relative;
                        border-radius: 20px;
                        width: 150px;
                        font-family: $font-family-alt;
                        font-size: 18px;

                        &:before {
                            content:"";
                            opacity: 0;
                            width: 100%;
                            border-radius: 20px;
                            background: color(success);
                            color: color(light);
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            line-height: 34px;
                            transition: all linear .2s;
                        }

                        &:after {
                            content:"";
                            display: block;
                            position: absolute;
                            left: 2px;
                            top: 2px;
                            bottom: 2px;
                            width: 30px;
                            background: color(light);
                            line-height: 1;
                            text-transform: none;
                            border-radius: 50%;
                            z-index: 10;
                            transition: all linear .2s;
                        }

                        &.cancel {
                            &:after {
                                margin-left: calc( 100% - 34px);
                            }

                            &:before {
                                content:"Inscrit";
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .checkbox {
            margin: space() 0;

            input {
                display: none;

                &:checked + label {
                    &:before {
                        background: color(success);
                        border-color: color(success);
                    }

                    &:after {
                        display: inline-block;
                        font-family: 'main';
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-transform: none;
                        content: $icon-check;
                        background: none;
                        color: color(light);
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 20px;
                        text-align: center;
                        font-size: 8px;
                        line-height: 22px;
                    }
                }
            }

            label {
                font-family: $font-family-base;
                font-weight: 400;
                color: color(gray, darker);
                margin-bottom: 0;
                position: relative;
                padding-left: 30px;

                &:before {
                    display: inline-block;
                    content:"";
                    border: 1px solid rgba(color(primary), .2);
                    width: 20px;
                    height: 20px;
                    border-radius: 5px;
                    vertical-align: middle;
                    position: absolute;
                    left: 0;
                }
            }
        }

        .slg-messages {
            order: 3;
        }

        #person-info {
            margin-top: 0;
            padding-top: 0;
            padding-bottom: space();
            border-bottom: 1px solid color(gray, light);
            order: 1;

            // @include xs {
            //     margin-left: -($grid-gutter-width);
            //     margin-right: -($grid-gutter-width);
            // }

            h4 {
                padding: 0 0 10px;
            }

            .email {
                margin: 0 0 space(2);

                // @include xs {
                //     align-items: flex-end;
                //     flex-wrap: wrap;
                //     display: block;
                // }

                    // span {
                    //     display: block;
                    //
                    //     @include xs {
                    //         margin-bottom: $grid-gutter-width/2;
                    //         display: block;
                    //     }
                    // }

                input {
                    width: 100%;
                    border-radius: 5px;
                }
            }

            .email {
                label {
                    font-family: $font-family-title;
                    font-size: 22px;
                    font-weight: 700;
                    display: block;
                    color: color(primary);

                    @include smmdlg {
                        font-size: 26px;
                    }
                }
            }

            input[type="text"] {
                border: 1px solid rgba(0,57,120,.3);
                border-color: rgba(color(primary), .3);
                padding: space(1) space(1);
                font-size: font-size(base);

                &:focus,
                &:hover {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        button {
            background: color(primary);
            float: left;
            border: none;
            padding: space(0.5) space(1);
            color: color(light);
            text-transform: uppercase;
            font-size: font-size(h4);
            border: 1px solid color(primary);
            white-space: nowrap;
            margin: 20px auto 50px;
            display: block;
            border-radius: 10px;
            order: 4;
            font-family: $font-family-alt;

            &:hover,
            &:focus {
                background: color(secondary);
                border-color: color(secondary);
                color: color(primary);
            }

            &:disabled,
            &[disabled]{
                border: 1px solid #cccccc;
                background-color: #cccccc;
                color: #666666;

                &:hover,
                &:focus {
                    background: #ccc;
                    border-color: #ccc;
                }
            }

            @include smmdlg {
                float: none;
            }
        }
    }

    .form-list {
        order: 2;

        h2 {
            display: block;
            font-family: $font-family-title;
            font-size: 22px;
            font-weight: 700;
            display: block;
            color: color(primary);

            @include smmdlg {
                font-size: 26px;
            }
        }
    }
}
