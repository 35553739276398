r-wrapper {
  background: color(gray, lighter);
}

// Main container
//

r-main {
  // max-width: 1300px;
  margin: 0 auto;
  width: 100%;

  @include mdlg {
    // padding: space();
  }
}

// Main content
//

r-main--content {
  display: block;
  // padding: space(.5);
  // max-width: 100%;

  @include mdlg {
    // float: left;
    // padding: 0 0 0 space(2);
    // width: 75%;
  }

  // Modifier

  &.r--creation {
    padding-left: 0;
  }
}

.node {
  @include mdlg {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 1200px;

    .gr-two-col & {
      // max-width: 1028px;
    }

    .gr-two-col.not-equal-columns & {
      max-width: 1028px;
    }

  }

  .field-collection-container,
  >h2 {
    width: 100%;
  }
}

.cm-form-left,
.cm-form-right {
  background: color(light);
  box-shadow: 0 0 1px color(gray);
  flex: 1;
  margin: space();
}