//
// Breadcrumb
//

// Breadcrumb container
//

r-breadcrumb {
    background: color(gray, lighter);
    font-family: $font-family-alt;
    padding: 0 calc(50vw - 650px + #{space(.5)});
}


// Breadcrumb item links
//

r-breadcrumb--item {
    a {
        font-size: font-size(button);
        padding-left: space(.5);
        padding-right: space(.5);
        text-decoration: underline;

        &:hover {
            color: color(gray, dark);
            text-decoration: none;
        }
    }

    +r-breadcrumb--item {
        &::before {
            content: $icon-arrow-down;
            font-family: main;
            font-size: .75rem;
            font-weight: bold;
        }
    }

    .active,
    .-is-active {
        a {
            text-decoration: none;
        }
    }
}