//
// Menu
//

// Base styles
//

r-menu {
    font-family: $font-family-alt;
    font-size: font-size(base);
    max-width: 100vw;
    overflow-x: scroll;

    @include mdlg {
        overflow-x: auto;
    }
}

r-menu--item,
r-submenu--item {
    border-bottom: 1px solid color(light);
}

r-menu--item {
    &.-is-open {
        r-menu--expand {
            &::before {
                content: $icon-arrow-bottom;
            }
        }
    }
}

r-submenu {
    border-top: 1px solid color(light);

    .r-menu--link {
        font-weight: normal;
        padding-left: space(2);
    }
}

.r-menu--link {
    color: color(gray, darker);
    font-weight: bold;
    padding: 6px space();
    white-space: nowrap;

    &:hover {
        background: color(gray, lighter);
        text-decoration: none;
    }

    &.-is-active {
        color: color(secondary);
    }
}

// Menu modifier
//

// r-sidebar {
//     &.r--main-sidebar {
//         r-menu {
//             background: color(gray, lighter);
//             border-radius: 5px;
//             color: color(menu);
//         }

//         .r-menu--link {
//             color: color(menu);

//             &.-is-active {
//                 background: color(primary);
//                 color: color(light);
//             }
//         }

//         .-is-active {
//             ~ r-menu--expand {
//                 background: color(primary);
//                 color: color(light);
//             }
//         }
//     }
// }

r-footer {
    r-menu {
        font-size: font-size(base);
        text-align: right;
    }

    r-menu--item {
        display: inline-block;
    }
}

// Menu = hamburger btn
//

r-menu-btn {
    &:hover {
        r-menu-btn--bar {
            @include smmdlg {
                background: color(primary, lighter);
            }
        }
    }
}

r-menu-btn--bar {
    background: color(light);
    border-radius: 2px;
}

// Menu expand btn
//

r-menu--expand {
    font-family: main;
    font-size: font-size(h4);
    line-height: 50px;
    width: 40px;

    &::before {
        content: $icon-arrow-down;
    }
}
