.gr-one-col {
  #suite {
    margin: auto;
  }

  /* -----------
    ARTICLE
     ---------- */
  .node-webform,
  .node-kiosque {
    background: color(light);
    padding: 20px;
    margin: 30px auto;
    border: 1px solid color(gray, light);

    header {
      max-width: 900px;
      margin: 0 auto;
      display: none;

      h2 {
        padding: 10px 30px 6px;
        display: inline-block;
        background: color(primary);
        color: color(light);
        text-transform: uppercase;
        font-size: 25px;
        margin: 0;

        a {
          color: color(light);
          text-decoration: none;
        }
      }
    }

    .field-name-field-descriptif-long {
      margin: 0 auto;
      padding: 10px 20px;
      border: 5px solid color(primary);
      max-width: 900px;
      display: none;
    }
  }

  .webform-page-0 {
    .node-webform {

      header,
      .field-name-field-descriptif-long {
        display: block;
      }
    }
  }

  /* -----------
    Form
     ---------- */
  .cm-form-content {
    margin: 0 auto;
    max-width: 600px;

    input {
      box-sizing: border-box;
    }

    .cm-adresse-belge.panel-default .panel-body {
      margin-right: 0;
    }

    a {
      &.btn-default {
        // background: color(gray);
        color: color(light);
        // font-family: $font-family-base;
        // width: 100%;
        // font-size: 18px;
        // padding: 8px 0;
      }
    }

    .form-actions {
      flex-wrap: nowrap;
      margin-top: 0;

      .button-primary {
        width: 100%;
        font-family: $font-family-base;
        font-weight: 400;
        font-size: 18px;
        padding: 8px 0;
      }
    }

    .webform-previous {
      &.btn {
        width: 100%;
        font-family: $font-family-base;
        font-weight: 400;
        font-size: 18px;
        padding: 8px 0;
        margin-right: 0;
      }
    }
  }
}

// Icon mdp oeil
.gr-one-col,
.gr-two-col {
  .password-container {
    position: relative;

    .webform-conditional-hidden {
      &+#eye-open {
        display: none;
      }
    }
  }

  .glyphicon-eye-open {
    &:before {
      bottom: 10px;
      content: $icon-eye-open;
      font-family: main;
      font-style: initial;
      position: absolute;
      right: 4px;
      text-align: center;
      width: 40px;

      // @include xssm {
      //   top: 35px;
      // }

      @include sm {
        left: calc(600px - 3rem);
      }
    }

    &.glyphicon-eye-close {
      &:before {
        // bottom: 10px;
        content: $icon-eye-closed;
        // font-family: main;
       // font-style: initial;
       // position: absolute;
       // right: 4px;
       // text-align: center;
       // width: 40px;

       // @include xssm {
       //   top: 35px;
       // }
      }
    }
  }

  /* -----------
    PROGRESS BAR
    ---------- */
  .rtecenter {
    @include xs {
      margin: 0 auto;
    }
  }

  .cm-progressbar {
    padding: 20px 0;
    margin: 40px auto;
    width: 100%;
    max-width: 90%;
    display: none;

    @include xs {
      padding: 0;
    }

    &:first-child {
      display: block;
      margin: auto;

      .cm-progressbar-page {
        display: block;

        &.current {
          background: none;
          font-size: 14px;

          .cm-progressbar-page-number {
            background: color(secondary);
            color: color(light);
          }
        }
      }

      .cm-progressbar-outer {
        display: flex;
        justify-content: center;
      }

      .cm-progressbar-page {
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
        text-align: center;
        flex: 1;
        font-size: 14px;
        line-height: 1.2;
        font-family: $font-family-alt;
        color: color(dark);
        position: relative;

        &:before,
        &:after {
          content: "";
          display: block;
          width: 50%;
          height: 4px;
          background: color(secondary);
          position: absolute;
          top: 20px;

          @include xs {
            top: 14px;
          }
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }

        &:first-child {
          &:before {
            background: color(light);
          }
        }

        &:last-child {
          &:after {
            background: color(light);
          }
        }
      }

      .cm-progressbar-page-number {
        align-items: center;
        margin: 0 auto 15px;
        display: flex;
        background: color(light);
        border: 1px solid color(secondary);
        border-radius: 50%;
        color: color(dark);
        justify-content: center;

        height: 46px;
        font-size: 21px;
        font-family: $font-family-button;
        font-weight: 800;
        line-height: 30px;
        text-align: center;
        position: relative;
        width: 46px;
        z-index: 30;

        @include xs {
          font-size: 16px;
          width: 30px;
          height: 30px;
        }

        &:after {
          display: none;
        }
      }

      .cm-progressbar-page-label {
        font-weight: 300;

        @include xs {
          display: none;
        }
      }
    }
  }
}

.gr-two-col {
  &.not-equal-columns {
    .cm-form-left {
      @include mdlg {
        padding: space() space();
        max-width: 277px;
      }
    }
  }

  .cm-form-left {
    @include mdlg {
      padding: space();
      //max-width: 277px;
    }
  }
}