// -----------------------------------------------------------------------------
// Base
// -----------------------------------------------------------------------------

body {
    font-family: $font-family-base;
    -webkit-font-smoothing:auto;
    // color: color(primary);
}

// Headings
//

h1 {
    @include font(h1);
    // border-bottom: 1px solid color(gray, light);
    color: color(primary);
    font-family: $font-family-base;
    font-size: font-size(25px, 35px, xs);
    font-weight: normal;
    margin: space() 0;
    // padding: 0 0 space();
    text-align: center;

    @include smmdlg {
        font-size: font-size(h2);
        margin: space(2) 0;
        // padding: 0 0 space(.75);
        // text-align: left;
    }

    // &.r--center {
    //     text-align: center;
    // }
}

h2 {
    @include font(h2);
    color: color(primary);
    font-family: $font-family-base;
    font-size: font-size-dynamic(18px, 24px, xs);
    font-weight: normal;
    margin: 0;

    @include smmdlg {
        font-size: font-size(h3);
        margin: 0;
    }

    strong {
        color: color(primary);
    }
}

h3 {
    @include font(h3);
    margin: 0 0 space();
}

// Text
//

p {
    max-width: 600px;
}

// r-p {
//     max-width: 600px;

//     em {
//         color: color(primary);
//     }
// }

a {
    color: color(secondary);

    &:hover {
        color: color(primary);
    }
}
