.gr-footer-menu {
    background: color(secondary);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;

    @include xssm {
        display: block;
    }

    li {
        list-style: none;
        padding: 0 10px;
        position: relative;

        @include xssm {
            display: inline-block;
        }

        &:after {
            content: '';
            display: block;
            background: color(light);
            width: 1px;
            height: 16px;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -8px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    a {
        color: color(light);
        font-family: $font-family-alt;
        font-size: 15px;
    }
}
