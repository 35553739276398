//
// Block
//

// Block Modifier
//

r-box {
    &.r--table {
        r-box--body {
            padding: 0;
            text-align: center;
        }
    }

    &.r--list {
        li {
            padding: space(.25);
        }
    }

    &.r--famille {
        padding: 0;

        @include smmdlg {
            padding: space(2);
        }

        h3 {
            font-family: $font-family-base;
            font-size: font-size(h4);

            @include smmdlg {
                font-size: font-size(h3);
            }
        }

        p {
            font-size: font-size(base);

            @include smmdlg {
                font-size: font-size(small);
            }
        }

        img {
            display: none;

            @include smmdlg {
                display: block;
            }
        }

        r-box--body {
            background: linear-gradient(15deg, color(primary, dark) 0%, color(primary) 60%);
            color: color(light);

            @include smmdlg {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &.r--faq,
    &.r--offres-perso {
        padding: space() space(.5);

        @include smmdlg {
            padding: space(3) space();
        }

        h3 {
            color: color(primary);
            font-size: font-size(h2);
            padding-bottom: 20px;
            position: relative;
            text-align: center;

            &::after {
                background: color(primary);
                bottom: 0;
                content: '';
                display: block;
                height: 5px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 80px;
            }
        }
    }


    &.r--offres-perso {
        background: color(light);

        h2 {
            color: color(light);
            font-size: font-size(h4);
            margin-bottom: 0;
            margin-top: space(2);
            padding-bottom: 0;
        }

        > r-box--body {
            margin: auto;
            max-width: 1100px + space(2);
            padding: 0;
        }

        r-box--footer {
            padding: 0;
        }

        .r-offre--bandeau {
            background: color(light);
            color: color(primary);
            font-size: font-size(base);
            margin-top: space(-1);
            padding: space(.25) space();
            position: absolute;
            right: 0;
            text-transform: uppercase;

            &::after {
                border-color: color(gray) transparent transparent transparent;
                border-style: solid;
                border-width: space() space() 0 0;
                bottom: space(-1);
                content: '';
                height: 0;
                position: absolute;
                right: space(-1);
                width: 0;
            }
        }

        .r--offre {
            background: color(primary);
            color: color(light);
            flex: 0 0 calc(100% - #{space(2)});
            margin: space();
            padding: space();
            position: relative;

            @include smmdlg {
                flex: 1;
                margin: 0 space() 0 0;
                min-width: 200px;
            }

            &:hover {
                background: color(primary, light);
            }
        }
    }

    &.r--faq {
        .r-list {
            margin: auto;
            max-width: 1100px;
        }

        .r-list--item {
            padding: 0 space();

            @include smmdlg {
                // padding: 0;
                width: 33.33%;
            }
        }
    }

    &.r--info-paiement {
        text-align: center;

        p {
            font-size: font-size(small);
            margin: auto;
        }

        .r-list {
            justify-content: center;
            margin-top: 0;
        }

        .r-list--item {
            padding: 0 space(.5) space();
        }
    }

    r-footer & {
        r-box--body {
            padding: 0;
        }

        p {
            margin: 0;
        }
    }
}

.r-list {
    list-style: none;
    padding: 0;

    &.r--service {
        list-style: none;
        padding: 0;

        @include smmdlg {
            display: flex;
        }

        li {
            flex: 1;
            margin-bottom: space(2);
            text-align: center;

            @include smmdlg {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            r-icon {
                display: block;
                font-size: font-size(h1);
                margin: space();

                @include smmdlg {
                    margin: space(.5);
                }
            }
        }
    }
}

.block-parent-offre {
    display: block;

    @include smmdlg {
        display: flex;
    }
}

.block-detail {
    width: 100%;

    @include smmdlg {
        width: 50%;
    }

    h2 {
        color: color(primary);
        margin-top: 0;
        text-align: center;

        @include smmdlg {
            text-align: left;
        }
    }

    h3 {
        color: color(primary);
        display: none;
        font-family: $font-family-base;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;

        @include smmdlg {
            display: block;
        }

        span {
            background: color(secondary);
            color: color(light);
            padding: 0 5px;
        }
    }

    .description {
        margin-bottom: 10px;
        text-transform: none;
        display: flex;
        align-items: flex-start;
        font-size: 17px;

        h4 {
            color: color(primary);
            font-size: 20px;
            font-family: $font-family-base;
            font-weight: 700;
            margin-top: 0;
        }

        img {
            margin-right: 15px;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        li {
            align-items: flex-start;
            display: flex;
            list-style: none;
            margin-bottom: 15px;
            padding-left: 10px;
            width: 50%;

            h4 {
                font-family: $font-family-base;
                font-size: 14px;
                font-weight: 300;
                margin-top: 0;
            }

            img {
                display: block;
                height: 48px;
                margin-right: 10px;
                width: 48px;
            }
        }
    }

    > p {
        line-height: 1.2;
    }

    small {
        font-size: 11px;
    }
}

.block-image {
    display: none;
    width: 50%;

    @include smmdlg {
        display: block;
    }
}
