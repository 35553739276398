//
// Forms
//

// Container
//

// .r-form {
//     padding: space(.75) space();

//     @include smmdlg {
//         padding: space(.75);
//     }

//     &.r--panel-right {
//         @include smmdlg {
//             width: 66%;
//         }
//     }
// }

// Core styles
//
.node-type-webform {
  h1 {
    display: none;
  }

  .field-name-field-descriptif-long {

    h1,
    h2,
    h3 {
      font-family: $font-family-title;
    }

    h1 {
      display: block;
      font-size: font-size(h1);
      font-weight: font-weight(h1);
      line-height: 1.3;
      margin: 0 0 space();
      text-align: left;
    }

    h2 {
      color: color(gray, darker);
      font-size: font-size(h2);
      font-weight: font-weight(h2);
      line-height: 1.3;
      margin: 0 0 space();
    }

    h3 {
      border-left: 5px solid color(primary);
      font-size: font-size(h3);
      font-weight: font-weight(h3);
      padding-left: 16px;
    }

    a {
      border-bottom: 1px solid color(gray);
      color: color(gray, darker);
      font-weight: font-weight(bold);

      &:hover {
        border: none;
        text-decoration: none;
      }
    }
  }
}

r-form--group {
  max-width: 600px;
  position: relative;

  // Form group modifiers
  //

  &.r--select {
    width: calc(100% / 3.1);
  }

  &.r--highlight {
    background: color(primary);
    color: color(light);
    padding: space();

    input {
      background: color(light);
    }

    a {
      color: color(light);
      text-decoration: underline;
    }
  }

  &.r--address-street {
    @extend %column-sm-8;
    padding-right: space();
  }

  &.r--address-num,
  &.r--address-box {
    @extend %column-sm-2;
  }

  &.r--address-zip,
  &.r--address-city {
    @extend %column-sm-6;
  }

  &.r--address-num,
  &.r--address-zip {
    padding-right: space();
  }

  &.webform-component--civilite {
    .r--radio {
      display: inline-block;
      width: auto;

      +.r--radio {
        margin-left: space(2);
      }

      label {
        margin-bottom: 0;
      }
    }
  }

  &.r--checkbox {
    // color: color(primary);
  }

  r-form--group {
    margin-bottom: space(0.5);
  }
}

r-form--text {
  color: color(gray);

  .r-form--action {
    position: absolute;
    right: 0;
    text-decoration: underline;
    top: 0;
  }
}

.form-text {
  @extend %form--input-text;
}

.form-text,
.r-form--input-text {
  border-radius: 5px;

  &:focus {
    background-color: $forms-input-focus-bg-color;
    border-color: $forms-input-focus-border-color;
  }

  &::placeholder {
    opacity: 1;
  }

  &[readonly],
  &[disabled] {
    background: color(gray, light);
    opacity: $components-opacity;
  }
}

label,
.r-form--label {
  color: color(gray);
  display: inline-block;
  font-size: font-size(small);
  font-weight: font-weight(bold);
  line-height: 1.5;
  margin-bottom: space(0.25);
  vertical-align: top;

  .small & {
    font-size: font-size(small);
    line-height: 1.6;
  }

  .form-required {
    color: color(error);
    font-weight: bold;
  }
}

.r-form--select,
.r-form--input-date {
  background-position: right 6px center, 100% 0;
  background-size: 12px, 1.5rem 2.5rem;
  border-radius: 5px;
  padding: 6px 1.5rem 6px 6px;

  &:focus {
    background-color: $forms-input-focus-bg-color;
    border-color: $forms-input-focus-border-color;
  }
}

.r-form--input-date {
  @extend %form--select;
}

.r-form--input-radio,
.r-form--input-checkbox {
  margin-top: space(0.5);

  &:first-child {
    margin-top: 0;
  }

  &.r--inline {
    margin-top: 0;

    @include smmdlg {
      display: inline-block;
      margin-top: 0;
    }

    +.r--inline {
      margin-top: space(0.5);

      @include smmdlg {
        margin-left: space(2);
      }
    }
  }

  &.r--obligatoire {
    color: color(error);
  }
}

// En savoir plus

.panel2 {
  li {
    margin-bottom: space();
  }
}

fieldset.panel-default {
  fieldset.panel-default {
    margin-bottom: 0px;
  }
}

fieldset.panel-default,
.cm-adresse-belge.panel-default {
  .panel-heading {
    span {
      color: color(gray, darker);
      font-size: font-size(base);
      font-weight: font-weight(bold);
    }
  }

  .panel-body {
    padding: 0 0 space();
  }

  &.collapsible {
    .panel-heading {
      margin: 0;

      .fieldset-legend {
        background: color(secondary);

        &:hover {
          background: color(secondary, dark);
        }
      }
    }

    >div {
      background: color(gray, lighter);
      padding: space();

      label {
        color: color(gray, darker);
      }

      input,
      select,
      textarea {
        background-color: color(light);
      }
    }
  }
}

.webform-component-radios,
.webform-component-checkboxes {
  >label {}
}

.webform-component--select-label-top,
.webform-component-checkboxes {
  >label {
    margin-bottom: space();
  }
}

// Drupal

.form-actions {
  input {
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    padding: space(0.5) space(1.5);
    background-color: color(secondary);
    color: color(primary);
    border: none;
    cursor: pointer;

    &:hover {
      background: color(secondary, light);
    }

    @include smmdlg {
      padding: $btn-padding;
    }
  }

  p {
    max-width: 700px;

    .gr-one-col &,
    .gr-two-col & {
      text-align: left;
    }
  }
}

.webform-component--numero-client {
  max-width: none;
}

.webform-component--numero-client--user-numero-abo {
  display: flex;
  align-items: center;

  label {
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: space();
  }

  input {
    border-radius: 5px;
    border: 1px solid $forms-input-border-color;

    &:focus {
      border-color: $forms-input-focus-border-color;
      outline: none;
      box-shadow: none;
      background: $forms-input-focus-bg-color;
    }
  }
}

//login
.cm-logout {
  display: none;
}

// barre d'étapes
.cm-progressbar {
  &:first-child {
    .cm-progressbar-page {
      // padding: space(.5) space(2) space(.25);

      &.current {
        background: color(light);
        border-bottom: 1px solid #e7e7e7;
        border-top: 0;
        color: color(secondary);

        @include smmdlg {
          font-size: 2rem;
        }
      }
    }
  }
}

.cm-progressbar-page {
  border-top: 1px solid #e7e7e7;
  font-size: 1.5rem;
  line-height: 3rem;
  padding: space(0.5);

  @include smmdlg {
    font-size: 2rem;
    padding: space() space(2);
  }

  &.current {
    border-top: 1px solid #e7e7e7;
    color: color(gray, dark);
  }
}

//picto tva tiré du field-text
.tva-be {
  flex-wrap: wrap;

  input {
    position: relative;
  }

  .field-prefix {
    border-radius: 4px 0 0 4px;
    bottom: 1px;
    color: color(secondary);
    background-color: color(gray, light);
    left: 1px;
    padding: 8px space() 8px;
    position: absolute;
    text-align: center;
    z-index: 1;

    &+input {
      padding: space(0.5) space() space(0.5) space(4);
    }
  }
}