.cm-thank {

  .cm-sepa-btn {
    background-color:none;
    border:1px solid #0000FF;
    border-radius:30px;
    height: 50px;
    padding:6px 10px;
    text-decoration: none;
    width: auto;

    span {
        color: #0000FF;
        font-size:13px;
        font-style:normal;
        font-weight: normal;
        vertical-align: text-bottom;
    }
  }
}
