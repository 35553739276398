//
// Buttons
//

// Base styles
//

.btn,
.r-btn {
  border-radius: $btn-border-radius;
  cursor: pointer;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  padding: space(0.5) space(1.5);

  &:hover {
    text-decoration: none;
  }

  // Buttons modifiers
  //

  &.r--primary {
    background-color: color(button);
    color: color(light);

    &:hover {
      background: color(button, light);
    }
  }

  &.r--secondary {
    background-color: color(secondary);
    color: color(light);

    &:hover {
      background: color(secondary, light);
    }
  }

  &.r--premium {
    background-color: color(premium);
    color: color(dark);
    margin-bottom: space(0.5);

    &:hover {
      background: color(premium, light);
    }
  }

  &.r--light {
    background-color: color(gray, light);
    color: color(light);

    &:hover {
      background: color(primary);
    }
  }

  &.r--rounded {
    border-radius: 30px;
  }

  &.r--block {
    display: block;
    margin: space(0.5) auto;
  }

  &.r--icon {
    font-size: font-size(h4);
    min-width: auto;
    padding: 0 space(0.5);
  }

  &.r--facebook {
    background: color(facebook);
    color: color(light);
    margin-left: 45px;
    position: relative;

    @include smmdlg {
      margin-left: 60px;
    }

    &::before {
      background: color(facebook);
      border-radius: 50%;
      color: color(light);
      content: $icon-error;
      display: block;
      font-family: main;
      line-height: 44px;
      height: 44px;
      left: -60px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 44px;
    }
  }

  &.r--link {
    color: color(primary);
    font-family: $font-family-base;
    font-size: inherit;
    font-weight: bold;
    padding-left: space(0.5);
    padding-right: space(0.5);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.r--min {
    min-width: 160px;
  }

  &:disabled,
  &.r--disabled {
    background: color(gray, light);
    border: 1px solid color(gray, light);
    color: color(gray);
    cursor: not-allowed;

    &:hover {
      background: none;
    }

    &.r--icon {
      padding: space(0.5) space(0.5) space(0.25);
    }
  }

  section.r--vertical & {
    margin-bottom: space(0.5);
  }
}

.btn {
  background-color: color(button);
  color: color(light);

  &:hover {
    background: color(button, light);
  }

  &.webform-previous {
    background: color(gray, light);
    color: color(gray);
    margin-right: space();
    width: auto;

    &:hover {
      background: color(gray, lighter);
      color: color(light);
      // text-decoration: underline;
    }
  }
}

.form-actions {
  .webform-previous {
    background: color(gray, light);
    color: color(light);
    margin-right: space();
    width: auto;

    &:hover {
      background: color(gray, lighter);
      color: color(gray);
      // text-decoration: underline;
    }
  }
}

button {
  cursor: pointer;
}

table {
  .swg-button,
  .swg-button-dark,
  .swg-button-light {
    width: 90%;
    max-width: 220px;
  }
}
