@font-face {
    font-family: 'roboto';    
    font-style: normal; 
    font-weight: 300;
    src:  url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/Roboto-Regular-webfont.woff2') format('woff2'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/Roboto-Regular-webfont.woff') format('woff')
}

@font-face {
    font-family: 'roboto_condensed';    
    font-style: normal; 
    font-weight: 300;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Light-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Light-webfont.eot?#iefix') format('embedded-opentype'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Light-webfont.woff') format('woff'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Light-webfont.ttf') format('truetype'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Light-webfont.svg#roboto_condensedlight') format('svg');

}

@font-face {
    font-family: 'roboto_condensed';
    font-style: normal;
    font-weight: 400;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Regular-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Regular-webfont.eot?#iefix') format('embedded-opentype'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Regular-webfont.woff') format('woff'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Regular-webfont.ttf') format('truetype'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Regular-webfont.svg#roboto_condensedregular') format('svg');
}

@font-face {
    font-family: 'roboto_condensed';
    font-style: normal;
    font-weight: 700;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Bold-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Bold-webfont.eot?#iefix') format('embedded-opentype'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Bold-webfont.woff') format('woff'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Bold-webfont.ttf') format('truetype'), 
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Roboto/RobotoCondensed-Bold-webfont.svg#roboto_condensedbold') format('svg');
}

@font-face {
    font-family: 'shadows';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/shadows/ShadowsIntoLightTwo-Regular.woff2') format('woff2'),
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/shadows/ShadowsIntoLightTwo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'monument';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/PPMonument/PPMonumentExtended-Book.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'monument';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/PPMonument/PPMonumentExtended-Medium.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'monument';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/PPMonument/PPMonumentExtended-Black.woff2') format('woff2');
    font-weight: 900;;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSans3';
    font-style: normal;
    font-weight: bold;
    src:  url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Source_Sans_3/SourceSans3-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSans3';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Source_Sans_3/SourceSans3-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSans3';
    font-style: normal;
    font-weight: 300;
    src:  url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Source_Sans_3/SourceSans3-Regular.woff2') format('woff2');
}