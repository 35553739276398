//
// Layout > Header
//

r-header {
    background: linear-gradient(to bottom, color(primary) 36px, color(light) 36px);
    box-shadow: 0 0 1px color(gray);
    display: grid;
    font-family: $font-family-alt;
    grid-template-areas:
    "logo"
    "menu";
    grid-template-rows: 36px 36px;
    text-align: center;

    @include mdlg {
        background: linear-gradient(to bottom, color(primary) 36px, color(light) 36px);
        grid-template-areas:
        "sso sso sso"
        "menu menu contact";
        grid-template-columns: minmax(0, 250px) minmax(0, auto) minmax(0, 330px);
        grid-template-rows: 36px 36px;
        padding: 0 space(2);
    }

    h1 {
        color: color(light);
        font-size: font-size(h3);
        margin: 0;
        padding: 0;
        text-align: left;
    }

    p {
        margin: 0;
    }

    r-box--body {
        padding: 0;
    }
}

//
// GRID
//
.r--menu-m-sudpresse {
    grid-area: menu;
}

.r-slogan {
    grid-area: titre;
}

.box_call_center {
    grid-area: contact;
}

.r-sso {
    grid-area: sso;
}



//
// Contenu header
//

.r-logo {
    display: block;
    margin: space(.25);
}

.r-logo--img {
    height: 30px;
    width: 200px;
}

.r-slogan {
    display: none;
    margin-top: space(.5);

    @include mdlg {
        display: block;
    }
}

.r-slogan--img {
    height: 22px;
}

.r--menu-m-lesoir {
    overflow-x: scroll;
    white-space: nowrap;

    @include mdlg {
        margin-left: 20px;
        overflow-x: auto;
    }
}
