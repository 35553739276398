.glide__arrow {
    &.glide__arrow--left {
        &::before {
            content: $icon-arrow-left;
        }
    }

    &.glide__arrow--right {
        &::before {
            content: $icon-arrow-right;
        }
    }
}
