.sso-user-menu {
  &:hover {
    .sso-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.r--connect_connected,
.r--connect_notconnected {
  background: color(primary);
  height: 36px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;

  r-box--body {
    display: block;
    padding: 0;
    width: 100%;

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      li {
        list-style: none;

        a {
          display: block;
          height: 36px;
          line-height: 36px;
          padding: 0 space(.5);
          padding-right: 0;
        }

        &.-is-active {
          position: relative;

          .sso-user-label {
            background: color(secondary);
          }

          ul {
            display: block;
          }
        }
      }

      .sso-logo {
        display: flex;
        flex: 1;
        line-height: 0;
        margin: 0 auto;
        padding: 0 40px 0 0;

        @include smmdlg {
          margin: 0 space(2);
        }

        &:before {
          content: "";
          display: block;
          background: color(secondary);
          height: 100%;
          position: absolute;
          right: calc(100% - #{space(2)});
          top: 0;
          width: 100%;
        }

        a {
          position: relative;
          display: none;

          @include mdlg {
            display: block;
          }

          &:first-child {
            background: color(secondary);
            display: block;
            margin-right: -11px;
            padding-right: 0;

            @include mdlg {
              width: auto;
            }

            img {
              transform: translateX(3px);
            }
          }
        }

        img {
          border-style: none;
          height: 10px;
          max-width: 100%;
          vertical-align: middle;
        }
      }

      &.sso-menu {
        background: color(light);
        box-shadow: 0 0 10px color(dark, .2);
        display: block;
        min-width: 250px;
        opacity: 0;
        padding: space(.5);
        position: absolute;
        right: space(0.5);
        text-align: left;
        top: 60px;
        transition: all 0.5s ease;
        visibility: hidden;
        z-index: 1;

        &:before {
          @include triangle(top, 30px, 15px, color(light));
          position: absolute;
          top: -15px;
          right: 10px;
        }

        li {
          border-top: 1px solid color(gray, lighter);
          color: color(gray);
          font-weight: 700;

          &:first-child {
            border-top: 0;
            padding: space(.5) space();

            +li {
              border-top: 0;
            }
          }

          a {
            color: color(secondary);
            font-size: font-size(h4);
            font-weight: 700;
            height: auto;
            line-height: 1.5;
            padding: space(.5) space();

            &:hover {
              text-decoration: none;
              color: color(primary);
            }
          }
        }
      }
    }

    .sso-user-label {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 space();
      font-size: 0;
      white-space: nowrap;

      &:before {
        font-family: 'main';
        content: $icon-user-si;
        color: color(light);
        font-size: font-size(h4);
      }

      &:hover,
      &:focus {
        background: color(secondary);
        text-decoration: none;

        &:before {
          content: $icon-user-plain-si;
        }
      }
    }
  }
}

.r--connect_connected {
  r-box--body {
    .sso-user-label {
      &:before {
        content: $icon-user-loggedin;
      }
    }
  }
}