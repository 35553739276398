//global
.node-kiosque {
    padding: space(2);
}

.node-type-kiosque {
    .cm-main-title {
        h1 {
            font-family: $font-family-base;
            font-size: 40px;
            margin-bottom: 0;

            @include sm {
                margin-top: 0;
            }
        }
    }

    .cm-form-content {
        padding: 15px 0 0;
    }

    .cm-progressbar {
        // margin: 0 -15px;
    }

    // .onglets-paiement {
    //     .panel-heading {
    //         display: none;
    //     }

    //     .panel-body {
    //         padding-top: 0;

    //         .tab-pane {
    //             border-bottom: 5px solid color(gray, light);
    //             display: block !important;
    //             margin-bottom: 30px;
    //             padding-bottom: 30px;

    //             &:last-child {
    //                 border: 0;
    //                 margin-bottom: 0;
    //                 padding-bottom: 0;
    //             }
    //         }
    //     }
    // }

    .field-name-field-visuel-grand {
        width: 100%;

        .img-responsive {
            box-shadow: 0 0 1px color(gray, light);
            transform: none;
        }
    }

    .field-name-field-prix-affiche {
        background: color(primary);
        color: color(light);
        font-family: $font-family-title;
        font-size: 55px;
        font-weight: 700;
        margin: 0;
        position: absolute;
        right: -20px;
        top: -20px;

        @include xssm {
            font-size: 45px;
            right: -10px;
            top: -10px;
        }
    }

    .main-container {
        background: color(gray, lighter);
        max-width: 1980px;

        @include mdlg {
            margin-top: 0;
        }
    }
}

.kiosque--3-col {
    .node {
        max-width: none;

        .cm-form-left {
            background: color(light);
            flex: 2;
            margin: 0 10px 10px;
            padding: 0;
            position: relative;
            // width: calc(66% - 60px);

            @include md {
                margin: 0 15px;
                // width: calc(66% - 30px);
            }

            @include xssm {
                // clear: both;
                margin: auto;
                // width: auto;
                // @include clearfix;
            }

            @include smmdlg {
                // margin-left: 45px;
                margin-right: 30px;
                margin-top: 0;
                padding: 15px;

            }
        }

        .cm-form-right {
            margin: 0 10px 10px;
            // padding: 15px;
            // width: calc(34% - 60px);

            @include md {
                margin: 0 15px 0 0;
                // width: calc(34% - 15px);
            }

            @include xssm {
                clear: both;
                margin: 10px auto;
                // width: auto;
            }

            @include smmdlg {
                margin-left: 0;
                margin-top: 0;
            }
        }

        .cm-kiosque-wrap {
            float: none;
            width: auto;

            @include smmdlg {
                float: right;
                width: 50%;
            }
        }

        .field-name-field-descriptif-long {
            border: 0;
            float: none;
            width: auto;
            padding-right: 10px;

            @include smmdlg {
                border-right: 1px solid color(primary);
                // float: left;
                width: 50%;
            }
        }
    }
}