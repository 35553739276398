/** Projet Charlotte / Voo **/
$voo-color: color(primary);

.charlotte {
    &.sudpresse-prospect {
        background-color: #E7EDF3;

        .webform-confirmation,
        .node {
            background: color(light);
            max-width: 940px;
        }

        r-wrapper {
            align-items: center;
            display: flex;
            padding: space()
        }
    }

    .charlotteIllustration {
        background-image: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketingSudpresse/img/jf-on-laptop.jpg');
        background-size: cover;
        background-position: center;
        display: none;
        grid-area: charlotte;
        //flex: 3;
        height: auto;
        //min-height: 180px;
        min-width: 53%;

        @include lg {
            display: block;
            min-height: 475px;
        }
    }

    h3 {
        color: color(secondary);
        font-size: 28px;
        font-weight: bold;
        margin-top: 25px;
        text-align: center;
    }

    #cm-main-menu,
    #cm-menu-formule,
    .call_center {
        display: none
    }

    p {
        font-size: font-size(small);
        color: color(gray, darker);
        text-align: center;
        max-width: 100%;
    }

    .trait_bloc {
        border-top: 2px solid color(secondary);
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    h4 {
        text-align: center;
    }

    .node {

        p {
            font-family: $font-family-roboto;
            font-size: font-size(small);
            margin: 0 space(2) 18px;
            //max-width: 500px;
        }

        .btn {
            background-color: color(secondary);
            border-radius: space(3);
            display: block;
            font-family: $font-family-source;
            margin: space();
            text-decoration: none;
            //max-width: 310px;
        }

        .btn-charlotte {
            font-family: $font-family-source;
            font-weight: normal;
            height: 50px;
            line-height: 33px;
            //padding-right: 100px;
            position: relative;
            //width: 250px;

            // img {
            //     position: absolute;
            //     height: 30px;
            //     right: 35px;
            //     top: 10px;
            // }
        }

        .btn-red {
            background: color(primary);
        }

        .btn-red-border {
            background: none;
            border: 3px solid color(primary);
            color: color(primary);
        }

        .trait_bloc {
            width: 60%;
        }
    }

    input[type="text"] {
        border-radius: 0;
        border: 1px solid lightgrey;
        font-family: sans-serif;
        padding: 0.4em 0.8em;
        width: 70%;
    }

    .popin {
        background-color: color(light);
        bottom: 0;
        border-top: 1px solid color(secondary);
        display: none;
        left: 0;
        margin: 4rem 0 0;
        padding: 0 space();
        position: absolute;
        right: 0;
    }

    .open-popin {
        background: color(secondary);
        border-radius: 100px;
        color: color(light);
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        height: 20px;
        margin-left: 0.5em;
        transform: translateY(-5px);
        width: 20px;
    }

    .close-popin {
        font-size: 2em;
        color: color(secondary);
        cursor: pointer;
        font-weight: 900;
        font-family: sans-serif;
        position: absolute;
        right: .5rem;
        top: -.5rem;
    }

    .popin-show {
        .field-name-body>*:not(.logo) {
            //display: none;
        }
    }

    .gr-footer-menu {
        background: color(primary);
    }

    .r--small {
        font-size: 15px;
    }

    .node,
    .cm-thank {
        background: color(light);
        border-radius: space();
        box-shadow: 0px 16px 24px 0px rgba(65, 65, 135, .1);
        display: flex;
        flex-wrap: initial;
        max-width: 940px;
        margin: auto;
        min-height: 400px;
        overflow: hidden;
        width: 100%;

        @include xssmmd {
            flex-direction: column;
            flex-wrap: wrap;
            margin: auto;
            max-width: 90%;
            padding: 1rem;
        }

        .field,
        r-form--group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            //     margin-bottom: 0;
            max-width: 100%;
            //     overflow: hidden;
            //     //padding: space(3) space(2.5);
            //     width: 100%;

            //     // @include xssm {
            //     //     flex-direction: column;
            //     //     //overflow: visible;
            //     // }


            &>div {
                display: block;
                // padding: 0 2rem;
                // flex-direction: column;
                // justify-content: space-between;
                // min-height: 500px;
                width: 100%;
                //         // padding: space(3) space(2.5);
                //         position: relative;


            }
        }
    }

    .cm-thank {
        margin: auto;
        padding: 0;

        a.btn {
            background-color: color(secondary);
            border-radius: space(3);
            color: color(light);
            display: block;
            font-family: $font-family-source;
            font-weight: 300;
            margin: space();
            text-decoration: none;

            &.monCompte {
                background-color: color(gray, light);
                color: color(gray, darker);
            }
        }

        .r--bouton-logos img {
            margin: space(.5) space;
            max-width: 130px;
            width: 30%;
        }
    }

    .cm-thank-msg {

        margin: 0;

        @include lg {
            display: grid;
            grid-template-columns: 1.25fr 1fr;
            grid-template-areas:
                "charlotte .""charlotte .";
        }

        >div {
            display: flex;
            flex-direction: column;
            grid-row: inherit;
            justify-content: space-between;
            min-height: 80%;
            padding: 0 2rem;

            &.charlotteIllustration {
                @include lg {
                    display: block;
                    grid-row: 1 / 3;
                    //min-height: 475px;
                }
            }

        }
    }


    .cm-form-left,
    .cm-form-right {
        margin: initial;
        background: none;
        box-shadow: none;
    }

    .first_bloc {
        //font-size: 18px;
        color: color(gray, dark);
        font-weight: bold;
        text-align: center;
    }

    .r-form {
        // margin-top: -16px;
        padding: 0;
        //display:flex;
    }

    r-footer {
        display: none;
    }

    .logo {
        padding: space(3) 0 space(2);
        text-align: center;

        img {
            height: space(1.75);
            width: auto;
        }
    }

    .bloc_1 {
        font-weight: bold;
    }

    .form-actions {
        display: flex;
        justify-content: center;
        margin-top: 0;
        //padding: 50px 0;
    }

    .field-name-body>*:not(.logo) {
        // padding: space(3) space(2.5);

        @include xssmmd {
            //padding: space(2) 20px;
        }
    }
}

.charlotte-nok,
.charlotte-ok,
.charlotte-twice {
    > :not(.logo) {
        //padding: 0 space(3);
    }

    .trait_bloc {
        margin: 0 55px 30px;
    }

    @include lg {
        .r-form {
            margin-top: -16px;
        }

        .form-submit {
            margin-bottom: 28px;
        }
    }
}

// Injoignable
.charlotte-unreachable {
    text-align: center;

    .bloc_1 {
        padding: 50px 50px 20px;
    }

    p {
        font-size: font-size(small);
        margin: 0;

        &:empty {
            margin: 0;
        }
    }

    .r--cross {
        font-size: 56px;
        font-weight: 900;
        color: color(primary);
        margin: auto;
        margin-top: 30px;
    }
}