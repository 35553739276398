.call_center {
    margin: auto;
    max-width: $html-max-width;
    position: relative;

    @include xssm {
        min-width: 290px;
    }
}

.box_call_center {
    color: color(primary);
    display: none;
    text-align: left;
    border-left: 0;
    padding-left: 0;

    @include mdlg {
        display: block;
    }

    &:hover {
        text-decoration: none;
    }

    h2 {
        font-size: 1.5em;
        margin: 0 0 5px;
    }

    p {
        border: 0;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    em {
        font-size: .75em;
    }

    .cm-box {
        background: color(light);
        box-shadow: 0 0 3px;
        color: color(gray, darker);
        display: none;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 45px;
        width: 250px;
        z-index: 100;

        h2 {
            font-size: font-size(h4);
            margin-top: 10px;
        }
    }

    .cm-call {
        align-items: center;
        color: color(primary);
        cursor: pointer;
        display: flex;
        font-size: font-size(small);
        height: 36px;
        justify-content: space-between;
        line-height: 1;

        r-icon {
            margin: space(.5);
        }

        a {
            align-items: center;
            color: color(primary);
            display: flex;
            text-decoration: none;
        }

        p {
            display: inline-block;

            @include xssm {
                display: none;
            }
        }

        .node-type-formulaire-perso &,
        .node-type-webform & {
            box-shadow: none;
        }
    }

    .node-type-formulaire-perso &,
    .node-type-webform & {
        color: color(light);
    }
}

.call_center {
    border: 0;

    @include xssm {
        border: 0;
        margin-top: 0;
        min-width: 320px;
    }

    a {
        text-decoration: underline;
    }

    .node-type-formulaire-perso,
    .node-type-webform {
        .landing & {
            background: color(dark, .5);
            padding-top: 15px;
            position: absolute;
            right: 15px;
            top: 15px;

            a {
                color: color(secondary);
            }
        }
    }
}

.i {
    display: inline-block;
    height: 15px;
    margin-right: 5px;
    text-indent: -999em;
    width: 15px;
}

.i-mail {
    background: url('../img/mail.svg') no-repeat center center;
    background-size: 100%;
}

.i-tel {
    background: url('../img/phone.svg') no-repeat center center;
    background-size: 60%;
}
