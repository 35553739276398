html {
    height: 100%;
}

.sudpresse-prospect {
    background: #242424 url('/sites/all/themes/custom/boutique/bootstrapMarketingSudpresse/img/fond.jpg') top center no-repeat;
    height: 100%;

    r-header,
    r-footer {
        display: none;
    }

    r-wrapper,
    .main-container {
        background: none;
        min-height: 100%;
    }

    .webform-confirmation {
        background: color(primary);
        margin: 30px auto;
        max-width: 480px;

        .cm-thank {
            background: url('/sites/all/themes/custom/boutique/bootstrapMarketingSudpresse/img/logo.png') top center no-repeat;
            background-size: 270px 80px;
        }

        &.cm-thank-msg {
            background: color(light);
            margin-bottom: 0;
        }
    }

    .form-actions {
        padding: 0 5px;
    }

    .node-webform {
        background: color(primary);
        border-radius: 10px;
        display: block;
        margin: 30px auto;
        max-width: 480px;

        header {
            background: url('/sites/all/themes/custom/boutique/bootstrapMarketingSudpresse/img/logo.png') center center no-repeat;
            background-size: 60%;
            height: 80px;

            h2 {
                display: none;
            }
        }

        .webform-client-form {
            background: color(light);
            border-radius: 5px;
            margin: 0 10px 20px;
            padding: 15px;
        }

        .form-type-radio {
            display: inline-block;
        }

        .webform-component--fieldset-identification--markup-connexion {
            display: inline-block;
            margin-right: 15px;

            p {
                margin: 0;
            }
        }

        .webform-component--fieldset-identification--markup-creation-compte {
            margin-top: 10px;
        }
    }

    .cm-progressbar {
        @include smmdlg {
            margin-left: -25px;
            margin-right: -25px;
        }
    }
}
